import { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';

import CardItem from './components/CardItem';
import FilterMobile from './components/FilterMobile';

import './style.css';

function Index(){	
	const [ac_CardData, setAC_CardData] = useState([]);
	useEffect(() => {
		const getAC_CardList = async () => {
			try {
				const response = await fetch('https://mana-zone.online/api/index.php?AlphaClashCardList');
				if(response.ok){
					const data = await response.json();
					setAC_CardData(data.sort((a, b) => a.acId.localeCompare(b.acId)));
					setFData(data.sort((a, b) => a.acId.localeCompare(b.acId)));
				} else {
					throw new Error('Failed to fetch data');
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			};
		};
		
		getAC_CardList();
	}, []);
	
	const [fData, setFData] = useState([]);
	
	const [fColorOpt, setFColorOpt] = useState({
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
	});
	const hColorFilter = (event) => {
		const { name, checked } = event.target;
		const updatedColors = {
			...fColorOpt,
			[name]: checked,
		};
		setFColorOpt(updatedColors);
		hFilterAll(0, updatedColors);
	};
	
	const [fSetOpt, setFSetOpt] = useState({
		0: false,
		1: false,
		2: false,
		3: false,
	});
	const hSetFilter = (event) => {
		const { name, checked } = event.target;
		const updatedSets = {
			...fSetOpt,
			[name]: checked,
		};
		setFSetOpt(updatedSets);
		hFilterAll(1, updatedSets);
	};
	
	const [fRarOpt, setFRarOpt] = useState({
		0: false,
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
		6: false,
		7: false,
		8: false,
		9: false,
	});
	const hRarFilter = (event) => {
		const { name, checked } = event.target;
		const updatedRarity = {
			...fRarOpt,
			[name]: checked,
		};
		setFRarOpt(updatedRarity);
		hFilterAll(2, updatedRarity);
	};
	
	const hFilterAll = (fType, filter) => {
		let preFilData = [...ac_CardData];
		
		//Color Filter
		let colors = [];
		if(fType === 0) { colors = filter; } else { colors = {...fColorOpt}; };
		const checkedColors = Object.keys(colors).filter((color) => colors[color]);
		
		if(checkedColors.includes('1') || checkedColors.includes('5')) {
			checkedColors.push('6');
		};
		
		if(checkedColors.length > 0) {
			preFilData = preFilData.filter((data) => {
				return checkedColors.includes(data.Color);
			});
		};
		//Set Filter
		let sets = [];
		if(fType === 1) { sets = filter; } else { sets = {...fSetOpt} };
		const checkedSets = Object.keys(sets).filter((set) => sets[set]);
		
		if(checkedSets.length > 0) {
			preFilData = preFilData.filter((data) => {
				return checkedSets.includes(data.Sets);
			});
		};
		
		//Rarity Filter
		let rarities = [];
		if(fType === 2) { rarities = filter; } else { rarities = {...fRarOpt} };
		const checkedRarities = Object.keys(rarities).filter((rarity) => rarities[rarity]);
		
		if(checkedRarities.length > 0) {
			preFilData = preFilData.filter((data) => {
				return checkedRarities.includes(data.Rarity);
			});
		};
		
		//Filter if no filter is turned on
		if(checkedColors.length === 0 && checkedSets.length === 0 && checkedRarities.length === 0) {
			setFData(ac_CardData);
		} else {
			setFData(preFilData);
		};
	};
	
	const [showFilterProp, setShowFilterProp] = useState(false);
			
	return (
		<Container>
			<Row hidden>
				<img className='pc' style={{padding: 0}} src='/assets/ManaZoneAdsDesktop.png' alt='' />
			</Row>
			<Row hidden>
				<img className='mb' style={{padding: 0}} src='/assets/MobileAds.png' alt='' />
			</Row>
			<Row className='text-center mt-4'>
				<h2>Alpha Clash Singles For Sale!</h2>
			</Row>
			<Row className='text-center mt-3'>
				<span>Pricings are still new and are still subject to change and more stock will be added soon after we organized our pulls</span>
			</Row>
			<Row className='text-center mt-1'>
				<span>Check out our <a href='/about'>About</a> to see future features of the site</span>
			</Row>
			<Row className='text-center mt-2'>
				<span>
					To make an order, please message us using the following <a href='https://www.facebook.com/profile.php?id=61555134397789'>link</a>
				</span>&nbsp;
				<span>We also sell booster box, case and packs! ^_^</span>
			</Row>
			<Row className='text-center mt-5 pc-filter'>
				<Row>
					<Col><h3>Filter:</h3></Col>
				</Row>
				<Row className='mb-3'>
					<Col></Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='1'
									checked={fColorOpt.isBlack} 
									onChange={hColorFilter}
								/>
							</Col>
							<Col><img style={{maxWidth:'36px'}} src='/assets/sc-black.png' alt='' /></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='2'
									checked={fColorOpt.isBlue}
									onChange={hColorFilter}
								/>
							</Col>
							<Col><img style={{maxWidth:'36px'}} src='/assets/sc-blue.png' alt='' /></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='3'
									checked={fColorOpt.isGreen}
									onChange={hColorFilter}
								/>
							</Col>
							<Col><img style={{maxWidth:'36px'}} src='/assets/sc-green.png' alt='' /></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='4'
									checked={fColorOpt.isRed}
									onChange={hColorFilter}
								/>
							</Col>
							<Col><img style={{maxWidth:'36px'}} src='/assets/sc-red.png' alt='' /></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='5'
									checked={fColorOpt.isWhite}
									onChange={hColorFilter}
								/>
							</Col>
							<Col><img style={{maxWidth:'36px'}} src='/assets/sc-white.png' alt='' /></Col>
						</Row>
					</Col>
					<Col></Col>
				</Row>
				<Row className='mb-3'>
					<Col></Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='0'
									checked={fSetOpt.isAwakening}
									onChange={hSetFilter}
								/>
							</Col>
							<Col><span>Set1</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='1'
									checked={fSetOpt.isClashgrounds}
									onChange={hSetFilter}
								/>
							</Col>
							<Col><span>Set2</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='2'
									checked={fSetOpt.isYoD}
									onChange={hSetFilter}
								/>
							</Col>
							<Col><span>DB1</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='3'
									checked={fSetOpt.isUnrivaled}
									onChange={hSetFilter}
								/>
							</Col>
							<Col><span>Set3</span></Col>
						</Row>
					</Col>
					<Col></Col>
				</Row>
				<Row className='mt-3 mb-3'>
					<Col></Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='0'
									checked={fRarOpt.isPromo}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>P</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='1'
									checked={fRarOpt.isStarterRare}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>ST</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='2'
									checked={fRarOpt.isCommon}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>C</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='3'
									checked={fRarOpt.isUncommon}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>UC</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='4'
									checked={fRarOpt.isRare}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>R</span></Col>
						</Row>
					</Col>
					<Col></Col>
				</Row>
				<Row>
					<Col></Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='5'
									checked={fRarOpt.isEpic}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>E</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='6'
									checked={fRarOpt.isIconicRare}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>IR</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='7'
									checked={fRarOpt.isIconicArt}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>IA</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='8'
									checked={fRarOpt.isLegendary}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>L</span></Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Form.Check
									name='9'
									checked={fRarOpt.isAlphaRare}
									onChange={hRarFilter}
								/>
							</Col>
							<Col><span>AR</span></Col>
						</Row>
					</Col>
					<Col></Col>
				</Row>
			</Row>
			<Row>
				{fData.map((card, index) => (
					<CardItem
						key={index}
						cardData={card}
					/>
				))}
			</Row>
			<div className='mb btn-filter' onClick={() => setShowFilterProp(true)}>
				<img src='/assets/tempFilter.png' alt='' />
			</div>
			<FilterMobile showFilterProp={showFilterProp} setShowFilterProp={setShowFilterProp} />
		</Container>
	);
};

export default Index;